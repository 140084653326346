<template>
  <div>
    <!--begin::User-->
    <div>
      <div v-if="PlanMessage? true: false" class="card-header d-block border-0 pt-6 pb-0">
        <div>
          <b-alert show variant="danger">{{ PlanMessage }}</b-alert>
        </div>
      </div>
      <h3 class="card-label">
        {{ $t('inventories.inventory') }}
        <span class="text-muted pt-2 font-size-sm d-block"></span>
      </h3>

      <div class="row">
        <div class="col-md-6">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="col-lg-12 mb-5">
                  <label>{{ $t('inventories.name') }}<span class="text-danger">*</span></label>
                  <input type="text" v-model="data.name" class="form-control" :class="validation && validation.name ? 'is-invalid' : ''" :placeholder="$t('inventories.name')"/>
                  <span v-if="validation && validation.name" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.name[0] }}
                                    </span>
                </div>
              <div class="col-lg-12 mb-5">
                  <label>{{ $t('inventories.manager') }}<span class="text-danger">*</span></label>
                  <div class="input-group">
                    <multiselect v-model="manager"
                                 label="name"
                                 track-by="id"
                                 :placeholder="$t('inventories.manager')"
                                 :options="users_list"
                                 :multiple="false"
                                 :taggable="false"
                                 :class="validation && validation.manager_id ? 'is-invalid' : ''"
                                 :show-labels="false"
                                 :show-no-options="false"
                                 :show-no-results="false"
                                 @search-change="getUsersList($event)">
                    </multiselect>
                    <div class="input-group-prepend">
                      <a class="btn btn-primary" href="/users/users/create" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
                    </div>
                    <span v-if="validation && validation.manager_id" class="fv-plugins-message-container invalid-feedback">
                                            {{ validation.manager_id[0] }}
                                        </span>
                  </div>
                  <span class="form-text text-muted" style="text-align:end">{{ $t('type_at_least_three_letters') + ' ' + $t('inventories.manager') }}.</span>
                </div>
            </div>
          </div>
          <div class="card card-custom mt-5">
            <div class="card-body row">
              <div class="col-lg-6 mb-5">
                <label>{{ $t('inventories.num_work_hours') }}</label>
                <input type="number" v-model="data.num_work_hours" class="form-control" :class="validation && validation.num_work_hours ? 'is-invalid' : ''" :placeholder="$t('inventories.num_work_hours')"/>
                <span v-if="validation && validation.num_work_hours" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.num_work_hours[0] }}
                                    </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('inventories.start_work_time') }}</label>
                <b-form-input :id="'start_work_time'" :type="'time'" v-model="data.start_work_time" :class="validation && validation.start_work_time ? 'is-invalid' : ''"></b-form-input>
                <span v-if="validation && validation.start_work_time" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.start_work_time[0] }}
                                    </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('inventories.end_work_time') }}</label>
                <b-form-input :id="'end_work_time'" :type="'time'" v-model="data.end_work_time" :class="((validation && validation.end_work_time) || end_work_time_validation) ? 'is-invalid' : ''"></b-form-input>
                <span v-if="validation && validation.start_work_time" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.start_work_time[0] }}
                                    </span>
                <span v-else-if="end_work_time_validation" class="fv-plugins-message-container invalid-feedback">
                                        {{ end_work_time_validation }}
                                    </span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="col-lg-6 mb-5">
                <label>{{ $t('inventories.branch') }}<span class="text-danger">*</span></label>
                <div class="input-group">
                  <multiselect v-model="branch"
                               :class="validation && validation.branch_id ? 'is-invalid' : ''"
                               :placeholder="$t('inventories.branch')"
                               label="name"
                               track-by="id"
                               :options="branches"
                               :multiple="false"
                               :taggable="true"
                               :show-labels="false"
                               :show-no-options="false"
                               :show-no-results="false">
                  </multiselect>
                  <div class="input-group-prepend">
                    <a class="btn btn-primary" href="/settings/branches/create" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
                  </div>
                  <span v-if="validation && validation.branch_id" class="fv-plugins-message-container invalid-feedback">
                                            {{ validation.branch_id[0] }}
                                        </span>
                </div>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('inventories.type') }}<span class="text-danger">*</span></label>
                <div class="input-group">
                  <b-form-group :label="''" v-slot="{ ariaDescribedby }">
                    <b-form-radio-group>
                      <b-form-radio v-model="data.type" :aria-describedby="ariaDescribedby" name="inventories-type" value="1">{{ $t('internal') }}</b-form-radio>
                      <b-form-radio v-model="data.type" :aria-describedby="ariaDescribedby" name="inventories-type" value="2">{{ $t('external') }}</b-form-radio>
                    </b-form-radio-group>
                  </b-form-group>
                  <span v-if="validation && validation.type" class="fv-plugins-message-container invalid-feedback">{{ validation.type[0] }}</span>
                </div>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('inventories.inventory_class') }}<span class="text-danger">*</span></label>
                <div class="input-group">
                  <select name="" id="inventory_class" v-model="data.inventory_class" class="custom-select" :class="validation && validation.inventory_class ? 'is-invalid' : ''">
                    <option v-for="row in inventory_class_list" :value="row.id" :key="row.id">
                      {{ row.name }}
                    </option>
                  </select>
                  <span v-if="validation && validation.inventory_class" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.inventory_class[0] }}
                                    </span>
                </div>
              </div>
              <div class="col-lg-6 mb-5">
                <div>
                  <label>{{ $t('inventories.content_type') }}</label>
                  <multiselect v-model="content_types_data" :placeholder="$t('inventories.content_type')"
                               label="name" track-by="id" :options="content_type_list" :multiple="true" :taggable="true"
                               :class="validation && validation.content_types ? 'is-invalid' : ''"
                               :show-labels="false"
                               :show-no-options="false"
                               :show-no-results="false">
                  </multiselect>
                  <span v-if="validation && validation.content_types" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.content_types[0] }}
                                    </span>
                </div>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('inventories.num_employee') }}</label>
                <input type="number" v-model="data.num_employee" class="form-control" :class="validation && validation.num_employee ? 'is-invalid' : ''" :placeholder="$t('inventories.num_employee')"/>
                <span v-if="validation && validation.num_employee" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.num_employee[0] }}
                                    </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>&ensp;</label>
                <b-form-checkbox size="lg" v-model="data.is_default" name="check-button" switch>{{ $t('is_default') }}</b-form-checkbox>
              </div>
              <div class="col-lg-6 mb-5">
                <label>&ensp;</label>
                <b-form-checkbox size="lg" v-model="data.status" name="check-button" switch>{{ $t('status') }}</b-form-checkbox>
              </div>
            </div>
          </div>
          <div class="card card-custom mt-5">
            <div class="card-body row">
              <div class="col-lg-6 mb-5">
                <label>{{ $t('accounts') }}</label>
                <div class="input-group">
                  <treeselect
                      :options="accounts"
                      :load-options="loadOptions"
                      :multiple="false"
                      :value="data.account_id"
                      @input="updateValue($event)"
                      :searchable="true"
                      :disabled="isDisabled"
                      :show-count="true"
                      :no-children-text="$t('No_sub_options')"
                      :no-options-text="$t('No_options_available')"
                      :no-results-text="$t('No_results_found')"
                      :placeholder="$t('Select')">
                  </treeselect>
                  <div class="input-group-prepend">
                    <a class="btn btn-primary" href="/accounting/chart-accounts" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-5">
        <b-tabs content-class="mt-3" class="nav-custom-link">
          <b-tab :title="$t('inventories.address_information')" :active="((validation.country_id && validation.country_id.length > 0) || (validation.city_id && validation.city_id.length > 0))">
            <div class="card card-custom">
              <div class="card-body row">
                  <div class="col-lg-3 mb-5">
                    <label>{{ $t('inventories.country') }}<span class="text-danger">*</span></label>
                    <div class="input-group">
                      <!-- <select name="" id="country_id" v-model="data.country_id" class="custom-select">
                          <option v-for="row in countries" :value="row.code2" :key="row.code2">
                              {{ row.name }}
                          </option>
                      </select> -->
                      <multiselect v-model="country"
                                   :class="validation && validation.country_id ? 'is-invalid' : ''"
                                   :placeholder="$t('inventories.country')"
                                   label="name" track-by="code2"
                                   :options="countries"
                                   :multiple="false"
                                   :taggable="false"
                                   :show-labels="false"
                                   :show-no-options="false"
                                   :show-no-results="false"
                                   @search-change="getCountries($event)"
                                   :internal-search="false">
                      </multiselect>
                      <span v-if="validation && validation.country_id" class="fv-plugins-message-container invalid-feedback">
                                            {{ validation.country_id[0] }}
                                        </span>
                    </div>
                    <span class="form-text text-muted" style="text-align:end">{{ $t('type_at_least_three_letters') + ' ' + $t('branches.country') }}.</span>
                  </div>
                  <div class="col-lg-3 mb-5">
                    <label>{{ $t('inventories.city') }}<span class="text-danger">*</span></label>
                    <div class="input-group">
                      <!-- <select name="" id="cities" v-model="data.city_id" class="custom-select">
                          <option v-for="row in cities" :value="row.id" :key="row.id">
                              {{ row.name }}
                          </option>
                      </select> -->
                      <multiselect v-model="city"
                                   :class="validation && validation.city_id ? 'is-invalid' : ''"
                                   :placeholder="$t('inventories.city')"
                                   label="name" track-by="id"
                                   :options="cities"
                                   :multiple="false"
                                   :taggable="true"
                                   :show-labels="false"
                                   :show-no-options="false"
                                   :show-no-results="false">
                      </multiselect>
                      <div class="input-group-prepend">
                        <a class="btn btn-primary" :href="data.country_id?'/settings/cities/'+data.country_id:'#'" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
                      </div>
                      <span v-if="validation && validation.city_id" class="fv-plugins-message-container invalid-feedback">
                                            {{ validation.city_id[0] }}
                                        </span>
                    </div>
                  </div>
                  <div class="col-lg-3 mb-5">
                    <label>{{ $t('inventories.postal_code') }}</label>
                    <div class="input-group">
                      <input type="text" class="form-control" :class="validation && validation.postal_code ? 'is-invalid' : ''" v-model="data.postal_code" :placeholder="$t('inventories.postal_code')"/>
                      <span v-if="validation && validation.postal_code" class="fv-plugins-message-container invalid-feedback">
                                            {{ validation.postal_code[0] }}
                                          </span>
                    </div>
                  </div>
                  <div class="col-lg-3 mb-5">
                    <label>{{ $t('inventories.district') }}</label>
                    <div class="input-group">
                      <input type="text" class="form-control" :class="validation && validation.district ? 'is-invalid' : ''" v-model="data.district" :placeholder="$t('inventories.district')"/>
                      <span v-if="validation && validation.district" class="fv-plugins-message-container invalid-feedback">
                                            {{ validation.district[0] }}
                                          </span>
                    </div>
                  </div>
                  <div class="col-lg-3 mb-5">
                    <label>{{ $t('inventories.address_1') }}</label>
                    <div class="input-group">
                      <input type="text" class="form-control" :class="validation && validation.address_1 ? 'is-invalid' : ''" v-model="data.address_1" :placeholder="$t('inventories.address_1')"/>
                      <span v-if="validation && validation.address_1" class="fv-plugins-message-container invalid-feedback">
                                            {{ validation.address_1[0] }}
                                          </span>
                    </div>
                  </div>
                  <div class="col-lg-3 mb-5">
                    <label>{{ $t('inventories.address_2') }}</label>
                    <div class="input-group">
                      <input type="text" class="form-control" :class="validation && validation.address_2 ? 'is-invalid' : ''" v-model="data.address_2" :placeholder="$t('inventories.address_2')"/>
                      <span v-if="validation && validation.address_2" class="fv-plugins-message-container invalid-feedback">
                                            {{ validation.address_2[0] }}
                                        </span>
                    </div>
                  </div>
              </div>
            </div>
          </b-tab>
          <b-tab :title="$t('inventories.contact_information')" :active="((validation.mobile && validation.mobile.length > 0))">
            <div class="card card-custom">
              <div class="card-body row">
                <div class="col-lg-3 mb-5">
                  <label>{{ $t('inventories.mobile') }}<span class="text-danger">*</span></label>
                  <div class="input-group">
                    <vue-tel-input v-model="data.mobile" :class="validation && validation.mobile ? 'is-invalid' : ''"></vue-tel-input>
                    <span v-if="validation && validation.mobile" class="fv-plugins-message-container invalid-feedback">{{ validation.mobile[0] }}</span>
                  </div>
                </div>
                <div class="col-lg-3 mb-5">
                  <label>{{ $t('inventories.phone') }}</label>
                  <div class="input-group">
                    <vue-tel-input v-model="data.phone" :class="validation && validation.phone ? 'is-invalid' : ''"></vue-tel-input>
                    <span v-if="validation && validation.phone" class="fv-plugins-message-container invalid-feedback">{{ validation.phone[0] }}</span>
                  </div>
                </div>
                <div class="col-lg-3 mb-5">
                  <label>{{ $t('inventories.email') }}</label>
                  <div class="input-group">
                    <input type="email" class="form-control" :class="validation && validation.email ? 'is-invalid' : ''" v-model="data.email" :placeholder="$t('inventories.email')"/>
                    <span v-if="validation && validation.email" class="fv-plugins-message-container invalid-feedback">{{ validation.email[0] }}</span>
                  </div>
                </div>
                <div class="col-lg-3 mb-5">
                  <label>{{ $t('branches.fax') }}</label>
                  <div class="input-group">
                    <input type="text" class="form-control" :class="validation && validation.fax ? 'is-invalid' : ''" v-model="data.fax" :placeholder="$t('branches.fax')"/>
                    <span v-if="validation && validation.fax" class="fv-plugins-message-container invalid-feedback">{{ validation.fax[0] }}</span>
                  </div>
                </div>
              </div>
            </div>
          </b-tab>
        </b-tabs>

      </div>

      <div class="pl-0 pr-0">
        <div class="row">
          <div class="col-lg-6 mt-10">
            <button type="button" class="btn btn-primary mr-2" @click="save">{{ $t('save') }}</button>
          </div>
        </div>
      </div>


    </div>
    <!--end::User-->
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import moment from "moment/moment";

export default {

  data() {
    return {
      mainRoute: 'settings/inventories',
      mainRouteDependency: 'base/dependency',

      countries: [],
      cities: [],
      branches: [],
      inventory_class_list: [
        {id: 1, name: this.$t('inventory_class_list.main_inventory')}, // المخزون الرئيسي
        {id: 2, name: this.$t('inventory_class_list.factory_inventory')},  // جرد المصنع
        {id: 3, name: this.$t('inventory_class_list.custom_inventory')}, // جرد مخصص
        {id: 4, name: this.$t('inventory_class_list.shipping_inventory')}, // مخزون الشحن
        {id: 5, name: this.$t('inventory_class_list.maintenance_inventory')}, // جرد الصيانة
        {id: 6, name: this.$t('inventory_class_list.temp_inventory')}, // جرد مؤقت
      ],
      content_type_list: [
        {id: 1, name: this.$t('content_type_list.raw_materials')}, // مواد خام
        {id: 2, name: this.$t('content_type_list.salable_items')},  // مواد قابلة للبيع
        {id: 3, name: this.$t('content_type_list.mixed')}, // مختلط
        {id: 4, name: this.$t('content_type_list.samples')}, // عينات
        {id: 5, name: this.$t('content_type_list.maintenance_materials')}, // مواد صيانة
      ],
      idEdit: this.$route.params.id ? this.$route.params.id : '',
      data: {
        name: null,
        branch_id: null,
        type: null,
        inventory_class: null,
        content_types: [],
        num_employee: null,
        num_work_hours: null,
        start_work_time: null,
        end_work_time: null,
        country_id: null,
        city_id: null,
        postal_code: null,
        district: null,
        address_1: null,
        address_2: null,
        email: null,
        mobile: null,
        phone: null,
        fax: null,
        status: true,
        is_default: false,
        iso_code_phone: "",
        iso_code_mobile: "",
        account_id: null,
        manager_id: null,
      },
      isEditing: false,
      validation: {},
      country: "",
      city: "",
      isDisabled: false,
      content_types_data: [],
      accounts: [],
      iso_code_mobile: "",
      iso_code_phone: "",
      branch: null,
      manager: null,
      users_list: [],
      PlanMessage: null,
      is_time_false: false,
      end_work_time_validation: null,
    };
  },
  watch: {

    "data.mobile": function (val) {
      if (val !== null && val.length < 6) {
        this.data.mobile = null;
      }
    },

    "data.phone": function (val) {
      if (val !== null && val.length < 6) {
        this.data.phone = null;
      }
    },

    country: function (val) {
      if (val) {
        this.data.country_id = val.code2;
        if (val.id) {
          this.getCities(val.id);
        }
      } else {
        this.data.country_id = null;
      }
    },
    city: function (val) {
      if (val) {
        this.data.city_id = val.id;
      } else {
        this.data.city_id = null;
      }
    },
    branch: function (val) {
      if (val) {
        this.data.branch_id = val.id;
      } else {
        this.data.branch_id = null;
      }
    },
    manager: function (val) {
      if (val) {
        this.data.manager_id = val.id;
      } else {
        this.data.manager_id = null;
      }
    },

    "data.end_work_time": function (val) {
      if (val) {
        let startTime = moment(this.data.start_work_time, "HH:mm");
        let endTime = moment(val, "HH:mm");
        let duration = moment.duration(endTime.diff(startTime)).asMilliseconds();

        if (duration <= 0) {
          this.is_time_false = true;
          this.end_work_time_validation = this.$t('select_time_after') + ': ' + moment(this.data.start_work_time, "HH:mm").format('LT');
        } else {
          this.is_time_false = false;
          this.end_work_time_validation = null;
        }
      } else {
        this.is_time_false = false;
        this.end_work_time_validation = null;
      }
    },
    "data.start_work_time": function (val) {
      if (val) {
        let startTime = moment(val, "HH:mm");
        let endTime = moment(this.data.end_work_time, "HH:mm");
        let duration = moment.duration(endTime.diff(startTime)).asMilliseconds();

        if (duration <= 0) {
          this.is_time_false = true;
          this.end_work_time_validation = this.$t('select_time_after') + ': ' + moment(val, "HH:mm").format('LT');
        } else {
          this.is_time_false = false;
          this.end_work_time_validation = null;
        }
      } else {
        this.is_time_false = false;
        this.end_work_time_validation = null;
      }
    }
  },
  methods: {

    save() {
      if (this.is_time_false) {
        return false;
      }
      if (this.isEditing) {
        this.update();
      } else {
        this.create();
      }
    },

    create() {
      // this.data.status = this.data.status ? '1' : '0';
      // this.data.is_default = this.data.is_default ? '1' : '0';

      this.validation = {};
      this.data.content_types = (this.content_types_data ? this.content_types_data.map((row) => {
        return row.id;
      }) : []);

      ApiService.post(this.mainRoute, {
        ...this.data,
      }).then((response) => {
        this.validation = {};
        this.$successAlert(response.data.message);
        this.$router.push('/settings/inventories');
      }).catch((error) => {
        this.$errorAlert(error);
        this.validation = error.response ? error.response.data.errors : null;
      });
    },

    update() {
      // this.data.status = this.data.status ? '1' : '0';
      // this.data.is_default = this.data.is_default ? '1' : '0';
      this.validation = {};
      this.data.content_types = (this.content_types_data ? this.content_types_data.map((row) => {
        return row.id;
      }) : []);
      ApiService.put(this.mainRoute + '/' + this.idEdit, {
        ...this.data,
      }).then((response) => {
        this.validation = {};
        this.$successAlert(response.data.message);
        this.$router.push('/settings/inventories');
      }).catch((error) => {
        this.$errorAlert(error);
        this.validation = error.response ? error.response.data.errors : null;
      });
    },

    async getData() {
      this.reloadUploadAttachment = false;
      await ApiService.get(this.mainRoute + '/' + this.idEdit).then((response) => {
        this.isEditing = true;
        this.data = response.data.data;
        this.country = response.data.data.country;
        this.city = response.data.data.city;
        this.branch = response.data.data.branch;
        this.manager = response.data.data.manager;

        // this.iso_code_phone = String(response.data.data.iso_code_phone);
        // this.iso_code_mobile = String(response.data.data.iso_code_mobile);

        if (response.data.data) {
          this.content_types_data = this.content_type_list.filter((row) => response.data.data.content_types.includes(row.id));
        }

      });
    },

    getCountries(filter) {
      if (filter && filter.length >= 3)
        ApiService.get(this.mainRouteDependency + "/countries", {params: {filter: filter}}).then((response) => {
          this.countries = response.data.data;
        });
    },
    getDefaultCountries() {
      ApiService.get(`${this.mainRouteDependency}/default-country`).then((response) => {
        this.country = response.data.data;
      });
    },
    getCities(id) {
      ApiService.get(this.mainRouteDependency + `/cities/${id}`).then((response) => {
        this.cities = response.data.data;
      });
    },
    getBranches() {
      ApiService.get(this.mainRouteDependency + `/branches`).then((response) => {
        this.branches = response.data.data;
      });
    },
    loadOptions() {
    },
    updateValue(value) {
      this.data.account_id = value;
    },

    getAccounts() {
      ApiService.get(this.mainRouteDependency + `/account_parents`, {params: {checkDisabled: 1, key: 'inventory_accounts_routing'}}).then((response) => {
        this.accounts = response.data.data;
      });
    },
    getDefaultAccount() {
      ApiService.get(`${this.mainRouteDependency}/get_default_account/inventory_accounts_routing`).then((response) => {
        this.data.account_id = response.data.data.id;
      });
    },
    getUsersList(filter) {
      if (filter && filter.length >= 3)
        ApiService.get(this.mainRouteDependency + "/users", {params: {filter: filter}}).then((response) => {
          this.users_list = response.data.data;
        });
    },
    getPlanMessage() {
      ApiService.get(this.mainRouteDependency + `/check_limits/inventories`).then((response) => {
        this.PlanMessage = response.data.data;
      });
    },

    defaultDataForUser() {
      ApiService.get(this.mainRouteDependency + "/default_data_for_user").then((response) => {
        this.data.currency_id = response.data.data.currency_id;

        this.data.branch_id = response.data.data.branch_id;
        this.branch = response.data.data.branch;
      });
    }

  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.Inventories"), route: '/settings/inventories'}, {title: (this.idEdit ? this.$t('update') : this.$t('create'))}]);

    this.getBranches();
    this.getAccounts();
    this.getDefaultAccount();


    if (this.idEdit) {
      this.getData();
    } else {
      this.defaultDataForUser();
      this.getPlanMessage();
      this.getDefaultCountries();
    }

    this.$root.$emit('bv::toggle::collapse', 'accordion-1')
  },
};
</script>


